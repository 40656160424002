import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import models from "../../../models";
import { userDataSelector } from "../../../redux/selectors/user";
import newfeed from "../../../models/newfeed";
import TooltipUser from "../../common/TooltipUser";
import { convertDate, convertDateTime, getUserRole } from "../../../utils/common";
import { BOOK_TYPE_NAME, COLLAPSE_TYPE, MESSAGES, RESPONSE_CODE, TIME_BOOK_TYPE_NAME } from "../../../utils/constants";
import { Button } from "antd";
import user from "../../../models/user";
import TagsInput, { TagInputRef } from "../../common/TagInput";
import { addBookExecAndSupportApi } from "../../../apis/book";
import { showNotification } from "../../../utils/notification";
import { IMAGES } from "../../../assets";
import { Collapse } from 'react-collapse';
import moment from "moment";
import { EventBusName, onPushEventBus } from "../../../services/event-bus";

interface Props {
    data: newfeed.Book;
    userData: user.User
    collapseType?: number;
}

const Book = (props: Props) => {
    const { data, collapseType } = props;

    const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(!Boolean(collapseType && collapseType === COLLAPSE_TYPE.COLLAPSE))

    const { isManager } = getUserRole()

    const execInputRef = useRef<TagInputRef>(null)
    const supportInputRef = useRef<TagInputRef>(null)
    const processInputRef = useRef<TagInputRef>(null)
    const resultInputRef = useRef<TagInputRef>(null)

    const handleSelectListExec = (currentTags: user.UserSearch[]) => {
        const supportInput = supportInputRef.current?.getValue();
        const listSupport = supportInput?.map(i => i.user_id) || [];
        const processInput = processInputRef.current?.getValue();
        const listProcess = processInput?.map(i => i.user_id) || [];
        const resultInput = resultInputRef.current?.getValue();
        const listResult = resultInput?.map(i => i.user_id) || [];
        //
        const listExec = currentTags?.map(i => i.user_id) || [];
        addBookExecAndSupport(listExec, listSupport, listProcess, listResult);
    }

    const handleSelectListSupport = (currentTags: user.UserSearch[]) => {
        const execInput = execInputRef.current?.getValue();
        const listExec = execInput?.map(i => i.user_id) || [];
        const processInput = processInputRef.current?.getValue();
        const listProcess = processInput?.map(i => i.user_id) || [];
        const resultInput = resultInputRef.current?.getValue();
        const listResult = resultInput?.map(i => i.user_id) || [];
        //
        const listSupport = currentTags?.map(i => i.user_id) || [];
        addBookExecAndSupport(listExec, listSupport, listProcess, listResult);
    }


    const handleSelectListProcess = (currentTags: user.UserSearch[]) => {
        const supportInput = supportInputRef.current?.getValue();
        const listSupport = supportInput?.map(i => i.user_id) || [];
        const execInput = execInputRef.current?.getValue();
        const listExec = execInput?.map(i => i.user_id) || [];
        const resultInput = resultInputRef.current?.getValue();
        const listResult = resultInput?.map(i => i.user_id) || [];
        //
        const listProcess = currentTags?.map(i => i.user_id) || [];
        addBookExecAndSupport(listExec, listSupport, listProcess, listResult);
    }


    const handleSelectListResult = (currentTags: user.UserSearch[]) => {
        const supportInput = supportInputRef.current?.getValue();
        const listSupport = supportInput?.map(i => i.user_id) || [];
        const execInput = execInputRef.current?.getValue();
        const listExec = execInput?.map(i => i.user_id) || [];
        const processInput = processInputRef.current?.getValue();
        const listProcess = processInput?.map(i => i.user_id) || [];
        //
        const listResult = currentTags?.map(i => i.user_id) || [];
        addBookExecAndSupport(listExec, listSupport, listProcess, listResult);
    }

    const addBookExecAndSupport = async (
        list_exec: string[],
        list_support: string[],
        list_process: string[],
        list_result: string[],
    ) => {
        try {
            const response = await addBookExecAndSupportApi({
                list_exec,
                list_support,
                list_process,
                list_result,
                book_id: data.book_id
            })
            showNotification(response.message, response.code)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const toggleCollapse = () => setIsOpenCollapse(!isOpenCollapse)

    const renderListUserBook = () => {
        const listUser = data.list_exec?.concat(data.list_support || []).filter((v, i, a) => a.findIndex(v2 => (v2.user_id === v.user_id)) === i)
        return (
            <div className="flex">
                {listUser?.map((item, key) =>
                    <div key={item.user_id} className={`${key > 0 ? 'avatar-collapse-work-container' : ''}`}>
                        <TooltipUser
                            data={item}
                            className='avatar-collapse-work'
                        />
                    </div>
                )}
            </div>
        )
    }

    const renderCollapseAction = () => {
        return (
            <img
                src={isOpenCollapse ? IMAGES.CHEV_DOWN : IMAGES.CHEV_UP}
                alt='chev'
                className="chev-work"
                onClick={toggleCollapse}
            />
        )
    }

    const handleClickSchool = (schoolId: string) => () => {
        onPushEventBus(EventBusName.SELECT_SCHOOL_MODAL, schoolId)
    }

    useEffect(() => {
        if (collapseType && collapseType === COLLAPSE_TYPE.COLLAPSE) {
            setIsOpenCollapse(false)
        }
        else if (collapseType) setIsOpenCollapse(true)
    }, [collapseType])

    return (
        <div>
            <div className="full-width">
                {!isOpenCollapse ?
                    <>
                        <div className="row">
                            <div className="col-md-10 col-sm-12 col-xs-12 flex-row">
                                <TooltipUser data={data} />
                                <div className='flex-column mgl-2'>
                                    <p className="mgb-0">
                                        <span className="text-large black bold">{data.user_name}</span>
                                        <span className="black"> book lịch <span className="bold">{BOOK_TYPE_NAME[data.book_type]}</span> vào</span>
                                        <span className="bold">  {moment(data.time, 'H:mm:ss').format('H')} giờ {TIME_BOOK_TYPE_NAME[data.time_type]} {convertDate(data.book_date)}</span>
                                    </p>
                                    <p className="mgb-0 pointer" onClick={handleClickSchool(data.school_id)}>
                                        <span className="gray">Tại </span>
                                        <u><span className="bold">{data.name}</span></u>
                                    </p>
                                    <p className="mgb-0">
                                        <span className="gray">SL HS Đăng ký: <span className="bold">{data.student_total}</span>, Số lớp: <span className="bold">{data.class_total}</span>, Số cơ sở: <span className="bold">{data.base_total}</span></span>
                                    </p>
                                    <div className="mgt">
                                        {renderListUserBook()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-12 col-xs-12">
                                <div className="row-collapse-action">
                                    {renderCollapseAction()}
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="row">
                            <div className="col-md-4 col-sm-4 col-xs-12 flex-row">
                                <TooltipUser data={data} />
                                <div className='flex-column mgl-2'>
                                    <p className="black text-large bold">{data.user_name}</p>
                                    <p className="text-time silver">{convertDateTime(data.cr_date_time)}</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 flex-row item-center">
                                <p className="text-huge bold text-center">{TIME_BOOK_TYPE_NAME[data.time_type]} {convertDate(data.book_date)}</p>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="row-collapse-action">
                                    {renderCollapseAction()}
                                </div>
                            </div>
                        </div>
                    </>
                }
                <Collapse isOpened={isOpenCollapse}>
                    <div className="row mgt-2 align-center">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-large bold">Người đặt lịch:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12 form-control flex align-center">
                            <p className="mgb-0">{data.user_name}</p>
                        </div>
                    </div>
                    <div className="row mgt-2 align-center">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-large bold">Tên trường:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12 form-control flex align-center">
                            <p className="mgb-0">{data.name}</p>
                        </div>
                    </div>
                    <div className="row mgt-2 align-center">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-large bold">SL HS Đăng ký:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12 form-control flex align-center">
                            <p className="mgb-0">{data.student_total}</p>
                        </div>
                    </div>
                    <div className="row mgt-2 align-center">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-large bold">Số lớp:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12 form-control flex align-center">
                            <p className="mgb-0">{data.class_total}</p>
                        </div>
                    </div>
                    <div className="row mgt-2 align-center">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-large bold">Số cơ sở:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12 form-control flex align-center">
                            <p className="mgb-0">{data.base_total}</p>
                        </div>
                    </div>
                    <div className="row mgt-2 align-center">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-large bold">Người đo:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <TagsInput
                                data={data.list_exec}
                                disabled={!isManager}
                                onSelect={handleSelectListExec}
                                ref={execInputRef}
                            />
                        </div>
                    </div>
                    <div className="row mgt-2 align-center">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-large bold">Người hỗ trợ:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <TagsInput
                                data={data.list_support}
                                disabled={!isManager}
                                onSelect={handleSelectListSupport}
                                ref={supportInputRef}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p>Xử lý KQ:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <TagsInput
                                data={data.list_process}
                                disabled={Boolean(!isManager)}
                                onSelect={handleSelectListProcess}
                                ref={processInputRef}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p>Trả KQ:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <TagsInput
                                data={data.list_result}
                                disabled={Boolean(!isManager)}
                                onSelect={handleSelectListResult}
                                ref={resultInputRef}
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(Book)