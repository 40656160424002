import { Button, Modal, Switch } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getListMapApi } from "../../apis/school";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { getUserRole, openNewTab } from "../../utils/common";
import { MESSAGES, PROP, REPORT_NAME, RESPONSE_CODE, SCHOOL_STATUS_NAME } from "../../utils/constants";
import Title from "../common/Title";
import TagsInput, { TagInputRef } from "../common/TagInput";
import { showNotification } from "../../utils/notification";
import { deleteReportApi, updateReportApi } from "../../apis/report";
import report from "../../models/report";
import models from "../../models";
import { userDataSelector } from "../../redux/selectors/user";
import { connect } from "react-redux";
import user from "../../models/user";

type Props = {
    showModal: boolean;
    onClose: () => void;
    onUpdateSuccess: () => void;
    reportSelected: report.ReportData;
    userData: user.User
}

const ModalAddReport = (props: Props) => {
    const { showModal, onClose, onUpdateSuccess, reportSelected, userData } = props;
    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [type, setType] = useState('1')
    const [isAll, setIsAll] = useState(true)
    const [isPress, setIsPress] = useState(false)
    const usersRef = useRef<TagInputRef>(null)

    const { isManager } = getUserRole()

    const handleUpdateReport = async () => {
        try {
            if (!title) return showNotification('Tiêu đề không được để trống', RESPONSE_CODE.ERROR);
            if (!link) return showNotification('Link không được để trống', RESPONSE_CODE.ERROR);
            const listUserInput = usersRef.current?.getValue();
            const listUser = listUserInput?.map(i => i.user_id) || [];
            if (!isAll && listUser.length === 0) return showNotification('Người nhận không được để trống', RESPONSE_CODE.ERROR);
            setIsPress(true)
            const response = await updateReportApi({
                report_id: reportSelected.report_id,
                title,
                link,
                is_all: isAll ? '1' : '0',
                type,
                list_user: listUser
            })
            showNotification(response.message, response.code);
            setIsPress(false);
            if (response.code === RESPONSE_CODE.SUCCESS) {
                onUpdateSuccess();
                onClose();
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    const handleDeleteReport = async() =>{
        try {
            setIsPress(true)
            const response = await deleteReportApi({report_id: reportSelected.report_id})
            setIsPress(false);
            showNotification(response.message, response.code);
            if (response.code === RESPONSE_CODE.SUCCESS) {
                onClose();
                onUpdateSuccess();
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    const setData = (reportData: report.ReportData) => {
        setTitle(reportData.title || '')
        setLink(reportData.link || '')
        setType(reportData.type || '1');
        setIsAll(Boolean(reportData.is_all !== '0'))
    }

    useEffect(() => {
        setData(reportSelected)
    }, [reportSelected])


    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            <div className="row align-center mgt-3">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Tiêu đề:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={title}
                        onChange={({ target: { value } }) => setTitle(value)}
                        placeholder="Nhập tiêu đề"
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Link báo cáo:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={link}
                        onChange={({ target: { value } }) => setLink(value)}
                        placeholder="Nhập link"
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Người nhận:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12 flex">
                    <p className="mgr-2">Tất cả:</p>
                    <Switch
                        checked={isAll}
                        onChange={setIsAll}
                    />
                </div>
                {!isAll &&
                    <div className="mgt" style={{ width: '100%' }}>
                        <TagsInput ref={usersRef} data={reportSelected.list_user} placeholder="Nhập danh sách người nhận" />
                    </div>
                }
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Phân loại:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <select
                        className="form-control"
                        value={type}
                        onChange={({ target: { value } }) => setType(value)}
                    >
                        {Object.keys(REPORT_NAME).map((key) =>
                            <option value={key}>{REPORT_NAME[key]}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="mgt-3 item-center flex-column">
                <Button
                    className="mgt-4"
                    loading={isPress}
                    onClick={handleUpdateReport}
                >
                    {reportSelected.report_id ? 'Cập nhật' : 'Thêm mới'}
                </Button>
                {reportSelected.report_id && isManager &&
                    <Button
                        className="mgt-2 bg-danger"
                        loading={isPress}
                        onClick={handleDeleteReport}
                    >
                        Xoá
                    </Button>
                }
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(ModalAddReport)