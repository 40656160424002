import React, { useState } from 'react';
import { Affix, Tooltip } from 'antd';
import Icon from 'react-fontawesome';
import { NavLink, RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { KEY, ROUTES } from '../../utils/constants';
import { connect } from 'react-redux';
import models from '../../models';
import { countDataSelector, userDataSelector } from '../../redux/selectors/user';
import user from '../../models/user';
import { getAvatar, getUserRole } from '../../utils/common';
import Cookies from 'js-cookie';
import ModalChangePassword from '../modals/ModalChangePassword';
import { branchsSelector } from '../../redux/selectors/school';
import school from '../../models/school';
import { userActionCreators } from '../../redux/actions/user';

type NavBarProps = {
    icon: string;
    title: string;
    link: string;
    isNotify?: boolean
}

interface Props extends RouteComponentProps {
    userData: user.User;
    branchs: school.Branch[];
    getUserDataSuccess: typeof userActionCreators.getUserDataSuccess;
    changeBranch: typeof userActionCreators.changeBranch;
    countData: user.CountData
}

const NavBar = React.memo((props: Props) => {
    const { userData, history, branchs, changeBranch, countData } = props;

    const [showModalChangePass, setShowModalChangePass] = useState<boolean>(false)

    const route = useLocation();

    const toggleShowModalChangePass = () => {
        setShowModalChangePass(!showModalChangePass)
    }

    const handleLogout = () => {
        Cookies.remove(KEY.TOKEN);
        const win: Window = window;
        win.location = "/";
        localStorage.clear()
    }

    const navigateUserRoute = () => {
        history.push(ROUTES.USER)
    }

    const navigateAddUserRoute = () => {
        history.push(ROUTES.ADD_USER)
    }

    const navigateAddBranchRoute = () => {
        history.push(ROUTES.ADD_BRANCH)
    }

    const handleChangeBranch = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        changeBranch(value)
    }

    const { isManager, isGeneralManager } = getUserRole();

    const isShowSelectBranch = (isGeneralManager && [ROUTES.BOOK, ROUTES.REPORT].includes(route.pathname))

    const listNavBar: NavBarProps[] = [
        { icon: 'building', title: 'Công ty', link: ROUTES.ROOT },
        { icon: 'star', title: 'Khách VIP', link: ROUTES.VIP },
        { icon: 'heart', title: 'Khách thân thiết', link: ROUTES.LOYAL },
        { icon: 'file-text-o', title: 'Chưa triển khai', link: ROUTES.TODO },
        { icon: 'paper-plane-o', title: 'Đang triển khai', link: ROUTES.DOING },
        { icon: 'refresh', title: 'Chờ tái triển khai', link: ROUTES.WAITING },
        { icon: 'user', title: 'Việc cá nhân', link: ROUTES.PERSONAL, isNotify: Boolean(countData.countPersonal) },
        { icon: 'camera', title: 'Book máy', link: ROUTES.BOOK },
        { icon: 'calendar-check-o', title: 'Báo cáo', link: ROUTES.REPORT },
        // { icon: 'bell', title: 'Thông báo', link: ROUTES.NOTIFICATION },
    ]

    return (
        <Affix>
            <div>
                <div className='header-nav'>
                    {isShowSelectBranch &&
                        <div className='item-center select-branch-manager' style={{ width: '45vh' }}>
                            <select
                                className='header-branch-select'
                                value={userData.branch_id}
                                onChange={handleChangeBranch}
                            >
                                {branchs.map((item) =>
                                    <option value={item.branch_id} key={item.branch_id}>{item.branch_name}</option>
                                )}
                            </select>
                        </div>
                    }
                    {listNavBar.map((item, key) =>
                        <NavLink className='col-nav' to={item.link} exact={key === 0} activeClassName='selected-bar' key={key}>
                            <Icon
                                name={item.icon}
                                className='icon-header'
                            />
                            <div className='flex align-center'>
                                <p className='text-nav'>{item.title}</p>
                                {item.isNotify &&
                                    <div className='notify-header' />
                                }
                            </div>
                        </NavLink>
                    )}
                    {/* <div
                        className='col-nav flex-row'
                    >
                        <div className='relative'>
                            <Icon name='bell' className='icon-header' />
                            <p className='text-nav'>Thông báo</p>
                        </div>
                    </div> */}
                    <div className='col-nav'>
                        <Tooltip
                            overlayClassName='tooltip-user'
                            placement='bottom'
                            trigger='click'
                            title={(
                                <div className='tooltip-user-nav-container'>
                                    <p className='pointer' onClick={navigateUserRoute}>Hồ sơ</p>
                                    {isManager && !isGeneralManager &&
                                        <p className='mgt pointer' onClick={navigateAddUserRoute}>Quản lý nhân sự</p>
                                    }
                                    {isGeneralManager &&
                                        <p className='mgt pointer' onClick={navigateAddBranchRoute}>Quản lý chi nhánh</p>
                                    }
                                    <p className='mgt pointer' onClick={toggleShowModalChangePass}>Đổi mật khẩu</p>
                                    <p className='mgt pointer' onClick={handleLogout}>Đăng xuất</p>
                                </div>
                            )}
                        >
                            <img
                                className='avatar-user-bar'
                                alt='avatar-user'
                                src={getAvatar(userData.avatar)}
                            />
                            <p className='text-name-user-bar'>{userData.user_name}</p>
                        </Tooltip>
                    </div>
                </div>
                <ModalChangePassword
                    showModal={showModalChangePass}
                    onClose={toggleShowModalChangePass}
                />
            </div>
        </Affix>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
    branchs: branchsSelector(state),
    countData: countDataSelector(state)
})

const mapDispatchToProps = {
    getUserDataSuccess: userActionCreators.getUserDataSuccess,
    changeBranch: userActionCreators.changeBranch,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar))