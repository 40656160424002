import { useEffect, useRef, useState } from "react";
import { IMAGES } from "../assets";
import ModalAddReport from "../components/modals/ModalAddReport";
import { showNotification } from "../utils/notification";
import { DEFAULT_PAGE_LIMIT, MESSAGES, REPORT_NAME, RESPONSE_CODE } from "../utils/constants";
import report from "../models/report";
import { getListReportApi } from "../apis/report";
import TooltipUser from "../components/common/TooltipUser";
import { connect } from "react-redux";
import models from "../models";
import { userDataSelector } from "../redux/selectors/user";
import user from "../models/user";
import InfiniteScroll from "react-infinite-scroller";
import Loading from "../components/common/Loading";
import Empty from "../components/common/Empty";

type Props = {
    userData: user.User
}

const Report = (props: Props) => {
    const [inputSearch, setInputSearch] = useState<string>('')
    const [reportType, setReportType] = useState<string>('')
    const [showModalAdd, setShowModalAdd] = useState<boolean>(false)
    const [reports, setReports] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [reportSelected, setReportSelected] = useState<report.ReportData>({} as report.ReportData)

    const timeoutRef = useRef<NodeJS.Timeout>()

    const { userData } = props;

    const handleSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setInputSearch(value)
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            getListReport(value)
        }, 500)
    }

    const toggleModalAdd = () => {
        setShowModalAdd(!showModalAdd)
        setReportSelected({} as report.ReportData)
    }

    const getListReport = async (searchText = '') => {
        try {
            const response = await getListReportApi({
                search_text: searchText,
                report_type: reportType
            })
            if (response.code === RESPONSE_CODE.SUCCESS) {
                const reportList = response.data.reduce(function (r: any, a: any) {
                    r[a.type] = r[a.type] || [];
                    r[a.type].push(a);
                    return r;
                }, {});
                setReports(reportList);
                setIsLoading(false)
            }
            else showNotification(response.message, response.code)
        } catch (error) {
            console.log({ error });
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const handleOpenLink = (link: string) => () => {
        window.open(link, '_blank')
    }

    const handleOpenModalEdit = (data: report.ReportData) => () => {
        setShowModalAdd(true)
        setReportSelected(data)
    }

    useEffect(() => {
        getListReport();
    }, [reportType])

    return (
        <div>
            <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12 pd">
                    <input
                        className="form-control"
                        placeholder="Tìm kiếm báo cáo"
                        value={inputSearch}
                        onChange={handleSearch}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 pd">
                    <select
                        className="form-control"
                        value={reportType}
                        onChange={({ target: { value } }) => setReportType(value)}
                    >
                        <option value=''>Tất cả báo cáo</option>
                        {Object.keys(REPORT_NAME).map((key) =>
                            <option value={key}>{REPORT_NAME[key]}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="item-center mgt-2">
                <img
                    alt='icon-add'
                    src={IMAGES.PLUS}
                    className='icon-add-school'
                    onClick={toggleModalAdd}
                />
            </div>
            <div className="mgt-2">
                {isLoading && <Loading />}
                {(!isLoading && Object.keys(reports).length === 0) &&
                    <Empty />
                }
                {Object.keys(reports).map((key: any) =>
                    <>
                        <p className="bold text-huge mgt-2 text-center">{REPORT_NAME[key]}</p>
                        {reports[key].map((item: report.ReportData) =>
                            <div
                                className="row row-report mgt-2"
                                key={item.report_id}
                            >
                                <div className="col-md-8 col-sm-6 col-xs-12 report-col">
                                    <p
                                        className="title-report"
                                        onClick={handleOpenLink(item.link)}
                                    >
                                        {item.title}
                                    </p>
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-12 report-col item-center">
                                    <TooltipUser
                                        data={item}
                                        className='avatar-collapse-work'
                                    />
                                </div>
                                <div className="col-md-2 col-sm-4 col-xs-12 report-col">
                                    {item.is_all === '1' ?
                                        <p className="bold color-white">Tất cả mọi người</p>
                                        :
                                        <>
                                            <div className="flex">
                                                {item.list_user?.map((item, key) =>
                                                    <div
                                                        key={item.user_id}
                                                        className={`${key > 0 ? 'avatar-collapse-work-container' : ''}`}
                                                    >
                                                        <TooltipUser
                                                            data={item}
                                                            className='avatar-collapse-work'
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-12 report-col item-center">
                                    {item.user_id === userData.user_id &&
                                        <img
                                            src={IMAGES.EDIT_WHITE}
                                            alt='edit'
                                            className="edit-work-img mgr-2"
                                            onClick={handleOpenModalEdit(item)}
                                        />
                                    }
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <ModalAddReport
                showModal={showModalAdd}
                onClose={toggleModalAdd}
                onUpdateSuccess={getListReport}
                reportSelected={reportSelected}
            />
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(Report);