import { getAvatar } from "./common";

export const URI_PATH = process.env.NODE_ENV === 'production' ? 'https://happyidem.com/backend' : 'http://localhost/happyidem-backend';
export const URI_SOCKET = process.env.NODE_ENV === 'production' ? 'https://zcity.vn:4567' : 'http://localhost:4567';

export const KEY = {
	TOKEN: '@token',
}

export const ROUTES = {
    LOGIN: '/login',
    ROOT: '/',
    TODO: '/todo',
    DOING: '/doing',
    WAITING: '/waiting',
    PERSONAL: '/personal',
    BOOK: '/book',
    REPORT: '/report',
    NOTIFICATION: '/notification',
	USER: '/user',
	ADD_USER: '/add-user',
	ADD_BRANCH: '/add-branch',
	VIP: '/vip',
	LOYAL: '/loyal',
}

export const MESSAGES = {
	ERROR : 'Có lỗi xảy ra trong quá trình thực hiện'
}

export const RESPONSE_CODE = {
    SUCCESS: 1,
    ERROR: -1
}

export const SCHOOL_STATUS_NAME: any = {
	'6': 'Khách thân thiết',
	'5': 'Khách VIP',
	'4': 'Công ty',
    '1': 'Chưa triển khai',
    '2': 'Đang triển khai',
    '3': 'Chờ tái triển khai',
}

export const SCHOOL_STATUS = {
    TODO: '1',
    DOING: '2',
    WAITING: '3',
	COMPANY: '4',
	VIP: '5',
	LOYAL: '6',
}

export const PROP: any = {
	MODAL:{
		title: null,
		footer: null,
		bodyStyle: {background: 'white', borderRadius: '2vh'},
		zIndex: 999999,
		width: '70vh',
        centered: true,
	},
    TYPE_AHEAD:{
		isLoading: false,
		minLength: 3,
		labelKey: 'user_name',
		filterBy: ['user_name'],
		id: 'input-search-user',
		emptyLabel: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Không tìm thấy dữ liệu</p>
				</div>
			</div>
		),
		searchText: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Đang tìm kiếm</p>
				</div>
			</div>
		),
		promptText: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Đang tải ...</p>
				</div>
			</div>
		),
		renderMenuItemChildren: (option: any) => (
			<div className='flex-row item-center'>
				<div className='flex-row search-user'>
					<img alt='avatar' className='avatar-guest-small' src={getAvatar(option.avatar)} />
					<p className='search-user-text'>{option.user_name}</p>
				</div>
			</div>
		)
	},
	TOOPTIP:{
		placement: 'topLeft',
		style: {width: '240px'},
		mouseEnterDelay: 0.05,
		mouseLeaveDelay: 0.05
	}
}

export const LIST_PROGRESS = [
    {name: 'SALES', status: '0'},
    {name: 'PHÁT TB', status: '1'},
    {name: 'CSKH', status: '2'},
    {name: 'ĐẶT LỊCH ĐO', status: '3'},
    {name: 'ĐO', status: '4'},
    {name: 'XLKQ', status: '5'},
    {name: 'TRẢ KQ', status: '6'},
    {name: 'THU TIỀN', status: '7'},
]

export const LIST_WORK = [
    {name: 'Lịch hẹn', status: '1'},
    {name: 'Việc hằng ngày', status: '2'},
    {name: 'Việc hàng tuần', status: '3'},
    {name: 'Việc hàng tháng', status: '4'},
    {name: 'Việc hàng quý', status: '5'},
    {name: 'Việc quan trọng', status: '6'},
    {name: 'Nhật ký sales', status: '7'},
    {name: 'Nhật ký CSKH', status: '8'},
    {name: 'Nhật ký thực hiện', status: '9'},
]

export const DEFAULT_PAGE_LIMIT = 10

export const USER_ROLE_TYPE = {
	STAFF: '1',
	MANAGE_ASSISTANT: '2',
	MANAGE: '3',
	GENERAL_MANAGE_ASSISTANT: '4',
	GENERAL_MANAGE: '5',
}

export const USER_ROLE_NAME = {
	'1': 'Nhân viên',
	'2': 'Trợ lý giám đốc',
	'3': 'Giám đốc chi nhánh',
	'4': 'Trợ lý tổng giám đốc',
	'5': 'Tổng giám đốc'
}

export const COLLAPSE_TYPE = {
    FULL: 1,
    COLLAPSE: 2
}

export const BOOK_COLLAPSE_TYPE = {
    FULL: 1,
    COLLAPSE: 2,
	TABLE: 3
}

export const SCHOOL_KEY_NAME: any = {
	avatar: 'Ảnh đại diện',
	name: 'Tên trường',
	classify: 'Phân loại',
	mst: 'Mã số thuế',
	address: 'Địa chỉ',
	base_total: 'Số lượng cơ sở',
	class_total: 'Số lớp',
	student_total: 'SL Học sinh',
	contact_info: 'Thông tin liên hệ',
	data_link: 'Đường link dữ liệu',
	zalo_link: 'Đường link Zalo',
	ward_name: 'Xã/Phường',
	district_name: 'Quận/Huyện',
	province_name: 'Tỉnh/TP',
}

export enum TimeBookType{
	MORNING = '1',
	AFTERNOON = '2'
}

export const TIME_BOOK_TYPE_NAME: any = {
	1: 'Sáng',
	2: 'Chiều'
}

export const GENDER_NAME: any = {
	1: "Nam",
	2: "Nữ"
}

export const RELATION_SHIP_NAME: any = {
	1: "Độc thân",
	2: "Đã có gia đình",
	3: "Ly hôn"
}

export const CLASSIFY_NAME: any = {
	1: "MN",
	2: "TH",
	3: "THCS",
	4: "MNTT",
	5: "LC",
	6: "QT",
	7: "CTY"
}

export const RELATIONSHIP_NAME: any = {
	1: "Chưa kết hôn",
	2: "Đã kết hôn",
	3: "Góa",
	4: "Ly hôn/ly thân"
}

export const BOOK_TYPE_NAME: any = {
	0: "Chính thức",
	1: "Dự kiến",
}

export const REPORT_OPTION_USER_NAME: any = {
	0: "Tất cả",
	1: "Dự kiến",
}

export const USER_PROPERTY_NAME: any = {
	0: "Chọn tính chất nhân sự",
	1: "Fulltime - có đóng BHXH",
	2: "Fulltime - không đóng BHXH",
	3: "Cộng tác viên",
	4: "Partime",
	5: "Đối tác kinh doanh",
	6: "Đối tác chuyên môn",
	7: "Khách hàng"
}

export const ADD_USER_COLLAPSE_TYPE = {
    FULL: 1,
	TABLE: 2
}

export const REPORT_NAME: any = {
	1: "Báo cáo hoạt động",
	2: "Báo cáo tài chính",
	3: "Báo cáo thị trường - Khách hàng",
	4: "Báo cáo cá nhân",
	5: "Báo cáo hội đồng cổ đông",
	6: "Báo cáo cho đối tác chuyên môn",
}