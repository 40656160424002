import { URI_PATH } from "../utils/constants"

const API_PREFIX =  URI_PATH + '/api';

const authentication = {
    login: `${API_PREFIX}/authentication/action/login`,
}

const school = {
    getListSchool: `${API_PREFIX}/school/action/getListSchool`,
    updateSchool: `${API_PREFIX}/school/action/updateSchool`,
    getListMap: `${API_PREFIX}/school/action/getListMap`,
    updateProgessStatus: `${API_PREFIX}/school/action/updateProgressStatus`,
    getListBranch: `${API_PREFIX}/school/action/getListBranch`,
    changeCurators: `${API_PREFIX}/school/action/changeCurators`,
    searchSchool: `${API_PREFIX}/school/action/searchSchool`,
    addSchool: `${API_PREFIX}/school/action/addSchool`,
    togglePriority: `${API_PREFIX}/school/action/togglePriority`,
}

const location = {
    getListProvince: `${API_PREFIX}/location/action/getListProvince`,
    getListDistrict: `${API_PREFIX}/location/action/getListDistrict`,
    getListWard: `${API_PREFIX}/location/action/getListWard`,
}

const user = {
    getListUser: `${API_PREFIX}/user/action/getListUser`,
    getUserData: `${API_PREFIX}/user/action/getUserData`,
    getDataTooltipUser: `${API_PREFIX}/user/action/getDataTooltipUser`,
    changePassword: `${API_PREFIX}/user/action/changePassword`,
    getUserDataExtend: `${API_PREFIX}/user/action/getUserDataExtend`,
    updateUser: `${API_PREFIX}/user/action/updateUser`,
    getListUserFromManager: `${API_PREFIX}/user/action/getListUserFromManager`,
    updateBranch: `${API_PREFIX}/user/action/updateBranch`,
    getListManagerFromGeneralManager: `${API_PREFIX}/user/action/getListManagerFromGeneralManager`,
    toggleBlockUser: `${API_PREFIX}/user/action/toggleBlockUser`,
    resetPassword: `${API_PREFIX}/user/action/resetPassword`,
    uploadAvatar: `${API_PREFIX}/user/action/uploadAvatar`,
    changeBranch: `${API_PREFIX}/user/action/changeBranch`,
    getCountData: `${API_PREFIX}/user/action/getCountData`,
}

const work = {
    createWork: `${API_PREFIX}/work/action/createWork`,
    editWork: `${API_PREFIX}/work/action/editWork`,
    sendChat: `${API_PREFIX}/work/chat/sendChat`,
    getListChat: `${API_PREFIX}/work/chat/getListChat`,
    markWork: `${API_PREFIX}/work/action/markWork`,
    getListWork: `${API_PREFIX}/work/action/getListWork`,
}

const newfeed = {
    getListNewfeed: `${API_PREFIX}/newfeed/action/getListNewfeed`,
    getListNewfeedAdmin: `${API_PREFIX}/newfeed/action/getListNewfeedAdmin`,
}

const post = {
    publishPost: `${API_PREFIX}/post/action/publishPost`,
}

const video = {
    publishVideo: `${API_PREFIX}/video/action/publishVideo`,
}

const book = {
    updateBook: `${API_PREFIX}/book/action/updateBook`,
    getListBook: `${API_PREFIX}/book/action/getListBook`,
    deleteBook: `${API_PREFIX}/book/action/deleteBook`,
    addBookExecAndSupport: `${API_PREFIX}/book/action/addBookExecAndSupport`,
    getListBookPersonal: `${API_PREFIX}/book/action/getListBookPersonal`,
    unlockBook: `${API_PREFIX}/book/action/unlockBook`,
}

const report = {
    updateReport: `${API_PREFIX}/report/action/updateReport`,
    getListReport: `${API_PREFIX}/report/action/getListReport`,
    deleteReport: `${API_PREFIX}/report/action/deleteReport`,
}

export const END_POINTS = {
    ...authentication,
    ...school,
    ...location,
    ...user,
    ...work,
    ...newfeed,
    ...post,
    ...video,
    ...book,
    ...report
}